import { LayoutAdmin } from "@components/LayoutAdmin"
import { useStores } from "@hooks/use-stores"
import { Form } from "antd"
import { navigate } from "gatsby"
import _filter from "lodash/filter"
import { observer } from "mobx-react"
import React, { useEffect, useState } from "react"

import FormGeneralBanner from "../../../../modules/Banners/components/FormGeneralBanner"

const AdminGeneralBannerForm = (params) => {
  const [form] = Form.useForm()

  const { banners, upload } = useStores()
  const { registerGeneralBanner, updateGeneralBanner, deleteGeneralBanner } =
    banners
  const { getSignedUrlS3, uploadToS3 } = upload

  const [initialValues, setInitialValues] = useState({
    mobilePhotoImage: null,
    tabletPhotoImage: null,
    desktopPhotoImage: null,
  })
  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [currentBanner, setCurrentBanner] = useState(null)
  const [previewBanner, setPreviewBanner] = useState({})
  const [bannerImage, setBannerImage] = useState({
    mobile: null,
    tablet: null,
    desktop: null,
  })
  const [bannerFile, setBannerFile] = useState({})

  useEffect(() => {
    if (params.location.state) {
      const { edit, dataBanner } = params.location.state

      if (edit && dataBanner) {
        setIsEdit(edit)
        setCurrentBanner(dataBanner)

        const bannerPhoto = {
          mobile: {
            fileList: [
              {
                uid: 1,
                name: "Banner",
                status: "done",
                path: dataBanner.urlMobile,
                url: `${process.env.S3_IMAGES}/${dataBanner.urlMobile}`,
              },
            ],
          },
          tablet: {
            fileList: [
              {
                uid: 1,
                name: "Banner",
                status: "done",
                path: dataBanner.urlTablet,
                url: `${process.env.S3_IMAGES}/${dataBanner.urlTablet}`,
              },
            ],
          },
          desktop: {
            fileList: [
              {
                uid: 1,
                name: "Banner",
                status: "done",
                path: dataBanner.urlDesktop,
                url: `${process.env.S3_IMAGES}/${dataBanner.urlDesktop}`,
              },
            ],
          },
        }

        setInitialValues({
          pageName: dataBanner.pageName,
          pageNumber: dataBanner.pageNumber,
          mobilePhotoImage: bannerPhoto.mobile,
          tabletPhotoImage: bannerPhoto.tablet,
          desktopPhotoImage: bannerPhoto.desktop,
        })
        setBannerImage(bannerPhoto)
      }
    }
  }, [])

  const uploadBanner = async (type) => {
    if (!bannerFile[type]) {
      return isEdit
        ? currentBanner[
            `url${
              type.toString().charAt(0).toUpperCase() + type.toString().slice(1)
            }`
          ]
        : null
    }

    const paramsBanner = {
      mediaPath: "images",
      fileName: bannerFile[type].name,
      s3Path: "generalBanner",
    }

    const signedUrlBannerRes = await getSignedUrlS3(paramsBanner)

    if (!signedUrlBannerRes) {
      setLoading(false)
      return null
    }

    const { url, filePath } = signedUrlBannerRes
    const uploadToS3bannerRes = await uploadToS3(url, bannerFile[type])

    if (!uploadToS3bannerRes) {
      setLoading(false)
      return null
    }

    return filePath
  }

  const handleFinish = async (props) => {
    setLoading(true)

    const filePathBannerMobile = await uploadBanner("mobile")
    const filePathBannerTablet = await uploadBanner("tablet")
    const filePathBannerDesktop = await uploadBanner("desktop")

    const body = {
      pageNumber: props.pageNumber,
      pageName: props.pageName,
      urlMobile: filePathBannerMobile,
      urlTablet: filePathBannerTablet,
      urlDesktop: filePathBannerDesktop,
    }

    if (isEdit) {
      await updateGeneralBanner(currentBanner.id, body)
    } else {
      await registerGeneralBanner(body)
    }

    navigate("/admin/banners-gerais")
    setLoading(false)
  }

  const handlePreviewBanner = async (file) => {
    if (!file.url && !file.previewCover) {
      file.previewCover = await getBase64(file.originFileObj)
    }

    setPreviewBanner({
      visible: true,
      image: file.url || file.previewCover,
      title: file.name || file.url.substring(file.url.lasIndexOf("/") + 1),
    })
  }

  const cancelPreviewBanner = (isVisible) => {
    setPreviewBanner({ visible: isVisible })
  }

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  const handleChangeBanner = async ({ file, fileList }, type) => {
    setBannerFile({ ...bannerFile, [type]: file.originFileObj })

    const photoImage = fileList.length ? { fileList } : null

    setBannerImage({ ...bannerImage, [type]: photoImage })

    form.setFieldsValue({ photoImage })

    setPreviewBanner({ fileList })
  }

  const handleRemoveBanner = (file, type) => {
    const formFieldValue = form.getFieldValue(`${type}PhotoImage`)

    const photo = _filter(
      formFieldValue.fileList,
      (item) => file.uid !== item.uid
    )

    const photoImage = photo.length ? { fileList: photo } : null

    setBannerImage({ ...bannerImage, [type]: photoImage })
    form.setFieldsValue({ [`${type}PhotoImage`]: photoImage })
  }

  const handleRemoveGeneralBanner = async () => {
    await deleteGeneralBanner(currentBanner.id)

    navigate("/admin/banners-gerais")
  }

  return (
    <LayoutAdmin
      title="Banner"
      subTitle="Edite a imagem de banner da página."
      custombreadcrumbName={!isEdit ? "Cadastro" : "Edição"}
    >
      <Form
        form={form}
        className="form-banner ph16"
        layout="vertical"
        initialValues={initialValues}
        onFinish={handleFinish}
      >
        <FormGeneralBanner
          isEdit={isEdit}
          loading={loading}
          bannerImage={bannerImage}
          previewBanner={previewBanner}
          cancelPreviewBanner={cancelPreviewBanner}
          handlePreviewBanner={handlePreviewBanner}
          handleChangeBanner={handleChangeBanner}
          handleRemoveBanner={handleRemoveBanner}
          handleRemoveGeneralBanner={handleRemoveGeneralBanner}
        />
      </Form>
    </LayoutAdmin>
  )
}

export default observer(AdminGeneralBannerForm)
