import "@modules/Banners/style.module.less"

import { ButtonsForm } from "@components/ButtonsForm"
import { Icon } from "@components/Icons"
import { Col, Form, Input, Modal, Upload } from "antd"
import { navigate } from "gatsby"
import _get from "lodash/get"
import React from "react"

const FormGeneralBanner = (props) => {
  const {
    isEdit,
    loading,
    bannerImage,
    previewBanner,
    cancelPreviewBanner,
    handlePreviewBanner,
    handleChangeBanner,
    handleRemoveBanner,
    handleRemoveGeneralBanner,
  } = props

  const validatePhoto = () => ({
    validator(rule) {
      if (bannerImage[rule.field.replace("PhotoImage", "")]) {
        return Promise.resolve()
      }

      return Promise.reject("Por favor, adicione um banner.")
    },
  })

  return (
    <>
      <div className="bg-white br2 pa24 generalBannerFormContext bannerContext">
        <Col span={24}>
          <Col xs={24} md={12}>
            <Form.Item
              label="Nome da página"
              name="pageName"
              rules={[{ required: true, message: "Campo obrigatório" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <span className="f14 scorpion-main">
            São necessários 3 tamanhos da imagem para garantir a visibilidade em
            todos os dispositivos.
          </span>
          <div className="mt24 draggerCover">
            <Form.Item
              label="Smartphones: 767x400px."
              name="mobilePhotoImage"
              rules={[validatePhoto]}
            >
              <Upload.Dragger
                multiple={false}
                onPreview={handlePreviewBanner}
                onChange={(file) => handleChangeBanner(file, "mobile")}
                listType="picture-card"
                accept=".jpeg, .png, .jpg"
                maxCount={1}
                fileList={_get(bannerImage, "mobile.fileList", null)}
                onRemove={(file) => handleRemoveBanner(file, "mobile")}
                customRequest={({ onSuccess }) => {
                  setTimeout(() => {
                    onSuccess("ok")
                  }, 0)
                }}
              >
                <p>
                  <Icon name="Inbox" className="f48 mb16" />
                </p>

                <p>Clique ou arraste a foto para esta área.</p>
              </Upload.Dragger>
            </Form.Item>
          </div>
          <div className="mt32 draggerCover">
            <Form.Item
              label="Tablets: 1023x400px."
              name="tabletPhotoImage"
              rules={[validatePhoto]}
            >
              <Upload.Dragger
                multiple={false}
                onPreview={handlePreviewBanner}
                onChange={(file) => handleChangeBanner(file, "tablet")}
                listType="picture-card"
                accept=".jpeg, .png, .jpg"
                maxCount={1}
                fileList={_get(bannerImage, "tablet.fileList", null)}
                onRemove={(file) => handleRemoveBanner(file, "tablet")}
                customRequest={({ onSuccess }) => {
                  setTimeout(() => {
                    onSuccess("ok")
                  }, 0)
                }}
              >
                <p>
                  <Icon name="Inbox" className="f48 mb16" />
                </p>

                <p>Clique ou arraste a foto para esta área.</p>
              </Upload.Dragger>
            </Form.Item>
          </div>
          <div className="mt32 draggerCover">
            <Form.Item
              label="Desktop: 1290x1080px."
              name="desktopPhotoImage"
              rules={[validatePhoto]}
            >
              <Upload.Dragger
                multiple={false}
                onPreview={handlePreviewBanner}
                onChange={(file) => handleChangeBanner(file, "desktop")}
                listType="picture-card"
                accept=".jpeg, .png, .jpg"
                maxCount={1}
                fileList={_get(bannerImage, "desktop.fileList", null)}
                onRemove={(file) => handleRemoveBanner(file, "desktop")}
                customRequest={({ onSuccess }) => {
                  setTimeout(() => {
                    onSuccess("ok")
                  }, 0)
                }}
              >
                <p>
                  <Icon name="Inbox" className="f48 mb16" />
                </p>

                <p>Clique ou arraste a foto para esta área.</p>
              </Upload.Dragger>
            </Form.Item>
          </div>
        </Col>
      </div>

      <ButtonsForm
        onCancel={() => navigate("/admin/banners-gerais")}
        saveText={isEdit ? "Salvar alterações" : "Cadastrar banner"}
        cancelText="Descartar"
        buttonLoading={loading}
        showRemoveButton={isEdit}
        removeButtonText="Remover banner"
        contentWidth="calc(100vw - 256px)"
        handleRemoveEssay={handleRemoveGeneralBanner}
        removeDialogText="Deseja realmente remover este banner?"
      />

      <Modal
        visible={previewBanner.visible}
        title={previewBanner.title}
        onCancel={() => cancelPreviewBanner(false)}
        footer={null}
      >
        <img
          alt="Pré-visualização"
          className="w-100"
          src={previewBanner.image}
        />
      </Modal>
    </>
  )
}

export default FormGeneralBanner
